import { render, staticRenderFns } from "./TopBarItem.vue?vue&type=template&id=dcfcc396&"
import script from "./TopBarItem.vue?vue&type=script&lang=js&"
export * from "./TopBarItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports