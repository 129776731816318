


























import {
  defineComponent,
  ref,
  onMounted,
  onUnmounted,
} from "@nuxtjs/composition-api";
import TopBarLink from "./TopBarLink.vue";
import TopBarItem from "./TopBarItem.vue";

export default defineComponent({
  name: "TheTopBarLeft",
  props: ["blok"],
  components: {
    TopBarLink,
    TopBarItem,
  },
  setup(props) {
    const currentItem = ref(0);
    let intervalFade;
    onMounted(() => {
      intervalFade = setInterval(() => {
        if (props.blok.blocks.length - 1 === currentItem.value) {
          currentItem.value = 0;
        } else {
          currentItem.value++;
        }
      }, 3000);
    });
    onUnmounted(() => {
      clearInterval(intervalFade);
    });
    return {
      currentItem,
    };
  },
});
